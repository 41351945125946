import { Controller } from '@hotwired/stimulus';
import { createPopper } from '@popperjs/core';

export default class extends Controller {
  static targets = ['element', 'tooltip'];
  static values = {
    placement: { type: String, default: 'top' },
    offset: { type: Array, default: [0, 10] },
    delay: { type: Number, default: 0 }
  };

  connect() {
    // Create a new Popper instance
    this.popperInstance = createPopper(this.elementTarget, this.tooltipTarget, {
      placement: this.placementValue,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.offsetValue,
          },
        },
      ],
    });
  }

  show(event) {
    clearTimeout(this.timer);
    this.tooltipTarget.setAttribute('data-show', '');

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    this.popperInstance.update();
  }

  hide(event) {
    this.timer = setTimeout(() => {
      this.tooltipTarget.removeAttribute('data-show');
    }, this.delayValue);
  }

  // Destroy the Popper instance
  disconnect() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
    }
    clearTimeout(this.timer);
  }
}
