import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'fileBackName',
    'fileBackValidationErrors',
    'fileName',
    'identityProofDocumentType',
    'validationErrors',
  ];

  static values = {
    changeDocumentText: String,
  };

  requireDocumentType(event) {
    if (this.hasIdentityProofDocumentTypeTarget) {
      try {
        this.identityProofDocumentTypeTarget.required = true;
      } catch (e) {}
    }
  }

  displayFileName(event) {
    if (this.hasFileNameTarget) {
      const fileName = event.target.value.split('\\').pop();
      this.fileNameTarget.textContent = fileName;
    }
  }

  displayFileBackName(event) {
    if (this.hasFileBackNameTarget) {
      const fileName = event.target.value.split('\\').pop();
      this.fileBackNameTarget.textContent = fileName;
    }
  }

  displayUploadDocumentText() {
    if (this.hasUploadDocumentTextTarget) {
      try {
        this.uploadDocumentTextTarget.innerText = this.changeDocumentTextValue;
      } catch (e) {}
    }
  }

  clearValidationErrors() {
    if (this.hasValidationErrorsTarget) {
      try {
        this.validationErrorsTarget.innerText = '';
      } catch (e) {}
    }
  }

  clearFileBackValidationErrors(event) {
    const errorElement = event.target
      .closest('div')
      .querySelector('span.text-red-400');
    if (errorElement) {
      errorElement.textContent = '';
    }
  }

  triggerFileInput(event) {
    event.preventDefault();
    const targetId = event.currentTarget.dataset.target;
    const fileInput = document.getElementById(targetId);
    if (fileInput) {
      fileInput.click();
    }
  }
}
