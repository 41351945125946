import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "selectedKycType",
    "singleOrSeparateFileChoiceButtons",
    "choseSingleFile",
    "choseSeparateFiles",
    "fileBackInputBlock",
    "fileFrontAndBackInputBlock",
    "separateLabel",
    "singleLabel"
  ]

  connect() {
    this.toggleSingleFileOrMultipleFilesMenu();
  }

  toggleSingleFileOrMultipleFilesMenu() {
    this.#showInputsIfATypeIsSelected();
    this.#toggleSingleOrSeparateFileChoiceButtons();
    this.toggleFileBackInput();
  }

  toggleFileBackInput() {
    if (!this.hasChoseSeparateFilesTarget) return;

    if (this.choseSeparateFilesTarget.checked && this.#canSelectedDocumentTypeHaveABack()) {
      this.fileBackInputBlockTarget.classList.remove('hidden');
      this.fileBackInputBlockTarget.querySelectorAll('[name*="[file_back]"]').forEach((el) => {
        el.disabled = false;
        el.required = true;
      });

      this.separateLabelTarget.classList.add("bg-info");
      this.singleLabelTarget.classList.remove("bg-info");

      this.fileFrontAndBackInputBlockTarget.classList.add('md:grid-cols-2');
    } else {
      this.fileBackInputBlockTarget.querySelector("input").required = false;
      this.fileBackInputBlockTarget.classList.add('hidden');
      this.fileBackInputBlockTarget.querySelectorAll('[name*="[file_back]"]').forEach((el) => {
        el.disabled = true;
        el.required = false;
      });

      if (this.choseSingleFileTarget.checked) {
        this.separateLabelTarget.classList.remove("bg-info");
        this.singleLabelTarget.classList.add("bg-info");
      }

      this.fileFrontAndBackInputBlockTarget.classList.remove('md:grid-cols-2');
    }
  }

  #toggleSingleOrSeparateFileChoiceButtons() {
    if (!this.hasSingleOrSeparateFileChoiceButtonsTarget) return;

    if (this.#canSelectedDocumentTypeHaveABack()) {
      this.singleOrSeparateFileChoiceButtonsTarget.classList.remove('hidden');

      this.choseSingleFileTarget.checked = false;
      this.choseSeparateFilesTarget.checked = true;
    } else {
      this.singleOrSeparateFileChoiceButtonsTarget.classList.add('hidden');
    }
  }

  #canSelectedDocumentTypeHaveABack() {
    if (!this.hasSelectedKycTypeTarget) return false;

    const typesWithBack = JSON.parse(this.selectedKycTypeTarget.dataset.fileBackSelectionMenuTypesWithBack);
    const selectedValue = this.selectedKycTypeTarget.value;
    return typesWithBack.includes(selectedValue);
  }

  #showInputsIfATypeIsSelected() {
    // return if empty list
    if (!this.hasSelectedKycTypeTarget || this.selectedKycTypeTarget.options.length === 0) return;

    // Hide upload inputs if no type is selected
    if (this.selectedKycTypeTarget.selectedIndex === 0 &&
      this.selectedKycTypeTarget.options[0].value === "") {
      this.fileFrontAndBackInputBlockTarget.classList.add('hidden');
    } else {
      this.fileFrontAndBackInputBlockTarget.classList.remove('hidden');
    }
  }
}
