import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['copyText'];
  static values = { copiedText: String, initialText: String };

  copyTextValue(event) {
    event.preventDefault();

    const targetValue = event.currentTarget.dataset.targetValue;

    navigator.clipboard.writeText(targetValue);

    this.copyTextTarget.innerText = this.copiedTextValue;
  }

  resetText(event) {
    this.copyTextTarget.innerText = this.initialTextValue;
  }
}
