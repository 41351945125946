import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {documentUrl: String}

  connect() {
    this.#generateDocumentIframe(this.element);
  }

  #generateDocumentIframe(container) {
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.src = this.documentUrlValue;

    container.prepend(iframe);
  }
}
