import { Controller } from '@hotwired/stimulus';
import toastr from "toastr";

export default class extends Controller {
  static values = {message: String, level: String};

  connect() {
    this.initToast();
  }

  initToast() {
    if (this.levelValue === "error") {
      toastr.error(this.messageValue);
    } else {
      toastr.success(this.messageValue);
    }
  }
}
