import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {autoShow: Boolean, targetId: String}

  connect() {
    if (this.autoShowValue) { this.open() }
  }

  open() {
    const modal = this.hasTargetIdValue ? document.getElementById(this.targetIdValue) : this.element
    modal.classList.remove("hidden")
  }

  close() {
    this.element.classList.add("hidden")
  }
}
