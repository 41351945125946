import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="mobile-sidebar"
export default class extends Controller {
  static targets = [
    'closeButton',
    'backdrop',
    'drawer',
    'openButton',
    'openButtonWrapper',
    'body',
    'backdropContainer',
  ];

  connect() {}

  openMobileSidebar(event) {
    const {
      backdropTarget,
      drawerTarget,
      closeButtonTarget,
      openButtonTarget,
      openButtonWrapperTarget,
      bodyTarget,
    } = this;
    bodyTarget.classList.add('overflow-hidden');
    backdropTarget.classList.remove('-z-10');
    backdropTarget.classList.add('bg-dark/30', 'z-50');
    drawerTarget.classList.remove(
      'translate-x-full',
      'pointer-events-none',
      'opacity-0'
    );
    drawerTarget.classList.add('opacity-100');
    closeButtonTarget.classList.remove('hidden');
    openButtonTarget.classList.add('hidden');
    openButtonWrapperTarget.classList.remove('z-50');
    backdropContainer.classList.add('z-40');
  }

  closeMobileSidebar(event) {
    const {
      backdropTarget,
      drawerTarget,
      closeButtonTarget,
      openButtonTarget,
      openButtonWrapperTarget,
      bodyTarget,
    } = this;
    bodyTarget.classList.remove('overflow-hidden');
    backdropTarget.classList.remove('bg-dark/30', 'z-50');
    backdropTarget.classList.add('-z-10');
    drawerTarget.classList.remove('opacity-100');
    drawerTarget.classList.add(
      'translate-x-full',
      'pointer-events-none',
      'opacity-0'
    );
    closeButtonTarget.classList.add('hidden');
    openButtonTarget.classList.remove('hidden');
    openButtonWrapperTarget.classList.add('z-50');
    backdropContainer.classList.remove('z-40');
  }
}
