import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "icon", "button"]

  connect() {
    // Unfold only the first content
    this.contentTargets.forEach((content, index) => {
      if (index !== 0) {
        content.classList.add("hidden")
      }
    })

    this.buttonTargets.forEach((button, index) => {
      button.setAttribute('aria-expanded', index === 0 ? 'true' : 'false')
      button.querySelector('svg').classList.toggle('rotate-180', index === 0)
    })
  }

  toggle(event) {
    const index = parseInt(event.currentTarget.dataset.index)
    this.contentTargets[index].classList.toggle("hidden")
    this.iconTargets[index].classList.toggle("rotate-180")

    const button = event.currentTarget
    const expanded = button.getAttribute("aria-expanded") === "true"
    button.setAttribute("aria-expanded", !expanded)
  }
}
