import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['collapseContent', 'toggleCollapseButton'];

  static values = {
    showText: String,
    hideText: String,
  };

  toggleCollapse(event) {
    this.collapseContentTarget.classList.toggle('collapse-show');
    if (this.collapseContentTarget.classList.contains('collapse-show')) {
      this.toggleCollapseButtonTarget.innerHTML = this.hideTextValue;
    } else {
      this.toggleCollapseButtonTarget.innerHTML = this.showTextValue;
    }
  }
}
