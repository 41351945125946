import { Controller } from '@hotwired/stimulus';

import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Form from '@uppy/form';
import FileInput from '@uppy/file-input';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import French from '@uppy/locales/lib/fr_FR';

export default class extends Controller {
  initialize() {
    const directUploadForm = document.getElementById('direct-upload-form');
    let filePrefix = '';
    let allowedFileTypes = null;

    if (directUploadForm && directUploadForm !== '') {
      if (directUploadForm.dataset.prefix && directUploadForm.dataset.prefix !== '') {
        filePrefix = `${directUploadForm.dataset.prefix}/`;
      }

      if (directUploadForm.dataset.accept && directUploadForm.dataset.accept !== '') {
        allowedFileTypes = directUploadForm.dataset.accept.split(',');
      }

      delete directUploadForm.dataset.accept;
      delete directUploadForm.dataset.prefix;
    }

    this.uppy = new Uppy({
      autoProceed: false,
      locale: French,
      restrictions: {
        allowedFileTypes: allowedFileTypes,
      },

      onBeforeFileAdded: (currentFile, files) => ({
        ...currentFile,
        name: `${filePrefix}${currentFile.name}`,
      }),
    }).use(Dashboard, {
      height: '480px',
      hideProgressAfterFinish: true,
      hideRetryButton: true,
      inline: true,
      proudlyDisplayPoweredByUppy: false,
      target: '#drag-drop-area',
      width: '100%',
    }).use(AwsS3Multipart, {
      companionUrl: '/',
    }).use(FileInput, {
      inputName: 'files[]',
      pretty: true,
    }).use(Form, {
      addResultToForm: true,
      getMetaFromForm: false,
      resultName: 'file_data',
      submitOnSuccess: false,
      target: '#direct-upload-form',
      triggerUploadOnSubmit: false,
    });
  }

  connect() {
    this.uppy.on('complete', (result) => {
      if (result.failed.length === 0) {
        this.uppy.setState({ totalProgress: 100 });
        document.querySelector('.uppy-StatusBar').setAttribute('style', 'height: 50px;');
        document.querySelector('#direct-upload-form button[type=submit]').disabled = false;
        document.querySelector('#direct-upload-form button[type=submit]').classList.remove("!cursor-not-allowed");
      }
    });
  }
}
