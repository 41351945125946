import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    iframeEndpoint: String,
    signatureId: String,
  }

  connect() {
    const configuration = {};
    const iframeEndpoint = this.iframeEndpointValue;
    const signerId = this.signatureIdValue;
    universignSigInit(this.element.id, signerId, configuration, iframeEndpoint);
  }
}
